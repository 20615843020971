export const sections = [
  {
    id: "section-1",
    type: "opening",
    videoSrc: "/assets/videos/renders/compressed/Cube with logo.mp4",
    videoSrcMobile: "/assets/videos/renders/mobile/Cube with logo.mp4",
    textContent: [""],
    mobileTextContent: ["A new generation of affordable luxury"],
    onMobile: true,
    imageSrc: "/assets/stills/All Lights.jpg",
    poster: "/assets/stills/Cube Opening Poster.jpg",
  },
  {
    id: "section-1b",
    type: "sticky-text-opening",
    imageSrc: "/assets/stills/All Lights.jpg",
    textContent: ["A new generation of affordable luxury"],
    onMobile: false,
  },
  {
    id: "section-2",
    type: "sticky",
    videoSrc:
      "/assets/videos/renders/compressed/01_ACT ONE_A morning in the life_4k_24fps_OPT.mp4",
    videoSrcMobile:
      "/assets/videos/renders/mobile/01_ACT ONE_A morning in the life_4k_24fps_OPT.mp4",
    imageSrc: "/assets/stills/building-from-park.jpg",
    textContent: [],
    mobileTextContent: [
      "Cloud Apartments is reimagining ",
      "urban living in the modern era",
    ],
    loop: false,
    videoOpacity: 1,
    onMobile: true,
  },
  {
    id: "section-2b",
    type: "sticky-text",
    imageSrc: "/assets/stills/building-from-park.jpg",
    textContent: [
      "Cloud Apartments is reimagining ",
      "urban living in the modern era",
    ],
    onMobile: false,
  },
  {
    id: "section-3",
    type: "sticky",
    videoSrc:
      "/assets/videos/renders/compressed/02_ACT TWO_Balcony coffee_4k_24fps_OPT.mp4",
    videoSrcMobile:
      "/assets/videos/renders/mobile/02_ACT TWO_Balcony coffee_4k_24fps_OPT.mp4",
    textContent: ["Fully furnished with smart controls "],
    mobileTextContent: ["Fully furnished with smart controls "],
    imageSrc: "/assets/stills/fully furnished.jpg",
    loop: true,
    videoOpacity: 1,
    onMobile: true,
  },
  {
    id: "section-4",
    type: "sticky",
    videoSrc:
      "/assets/videos/renders/compressed/03_ACT THREE_Family_4k_24fps_OPT - edit.mp4",
    videoSrcMobile:
      "/assets/videos/renders/mobile/03_ACT THREE_Family_4k_24fps_OPT - edit.mp4",
    textContent: ["Intentionally designed for space and comfort"],
    mobileTextContent: ["Intentionally designed for space and comfort"],
    imageSrc: "/assets/stills/spacious.jpg",
    loop: true,
    onMobile: true,
  },
  {
    id: "section-5",
    type: "sticky",
    videoSrc:
      "/assets/videos/renders/compressed/04_ACT FOUR_Drone Out_4k_24fps_OPT - edit.mp4",
    videoSrcMobile:
      "/assets/videos/renders/mobile/04_ACT FOUR_Drone Out_4k_24fps_OPT - edit.mp4",
    textContent: [""],
    mobileTextContent: ["The future of affordable living"],
    loop: false,
    onMobile: true,
    imageSrc: "/assets/stills/facade.jpg",
  },
  {
    id: "section-6",
    type: "sticky-text",
    imageSrc: "/assets/stills/Comp 1_00178.jpg",
    textContent: ["The future of affordable living"],
    onMobile: false,
  },
  {
    id: "section-7",
    type: "sticky",
    videoSrc: "/assets/videos/renders/compressed/Factory 2.mp4",
    videoSrcMobile: "/assets/videos/renders/mobile/Factory 2.mp4",
    textContent: ["The future of how we build"],
    mobileTextContent: ["The future of how we build"],
    imageSrc: "/assets/stills/Factory.jpg",
    loop: true,
    onMobile: true,
    restart: true,
  },
  {
    id: "section-8",
    type: "sticky",
    videoSrc: "/assets/videos/renders/compressed/Machines 2.mp4",
    videoSrcMobile: "/assets/videos/renders/mobile/Machines 2.mp4",
    textContent: ["With automation and precision "],
    mobileTextContent: ["With automation and precision"],
    imageSrc: "/assets/stills/Machines.jpg",
    loop: true,
    onMobile: true,
  },
  {
    id: "section-9",
    type: "sticky",
    videoSrc: "/assets/videos/renders/compressed/Workers 2.mp4",
    videoSrcMobile: "/assets/videos/renders/mobile/Workers 2.mp4",
    textContent: ["To achieve the highest level of craftsmanship"],
    imageSrc: "/assets/stills/Workers.jpg",
    mobileTextContent: ["To achieve the highest level of craftsmanship"],
    loop: true,
    onMobile: true,
  },
  {
    id: "section-20",
    type: "sticky",
    videoSrc: "/assets/videos/renders/compressed/End scene.mp4",
    videoSrcMobile: "/assets/videos/renders/mobile/End scene.mp4",
    textContent: ["Welcome to life in the Cloud"],
    imageSrc: "/assets/stills/End Photo.jpg",
    mobileTextContent: ["Welcome to life in the Cloud"],
    loop: true,
    onMobile: true,
  },
  {
    id: "section-11",
    type: "form",
    onMobile: true,
  },
  {
    id: "section-12",
    type: "footer",
    onMobile: true,
  },
]
