import React, { useRef, useEffect, useState, lazy, Suspense } from "react"
import "../App.css"
import { sections } from "../sections/list"
import ScrollingImages from "./bodies/ScrollingImages"
import StickyImage from "./bodies/StickyImageWithText"
import StickyImageOpening from "./opening/StickyOpeningImageWithText"
import ScrollDownButton from "./ScrollDownButton"

// Lazy loading components
const StickyVideo = lazy(() => import("./bodies/StickyVideo"))
const StickyVideoMobile = lazy(() => import("./bodies/StickyVideoMobile"))
const StickyOpening = lazy(() => import("./opening/StickyOpening"))
const Footer = lazy(() => import("./extents/Footer"))
const FormComponent = lazy(() => import("./form/FormComponent"))




function throttle(func, limit) {
  let inThrottle
  return function () {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}


const Container = ({ highPerformance, userHasInteracted }) => {
  const containerRef = useRef(null)
  const scrollDirection = useRef("down")
  const [scrollTop, setScrollTop] = useState(0)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  // const [networkSpeed, setNetworkSpeed] = useState(null)
  const [isHighSpeed, setIsHighSpeed] = useState(true)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const setConnectionSpeed = () => {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection
      // setNetworkSpeed(connection?.effectiveType)
      setIsHighSpeed(["4g", "5g"].includes(connection?.effectiveType))
    }

    setConnectionSpeed()
    window.navigator.connection?.addEventListener("change", setConnectionSpeed)
    return () =>
      window.navigator.connection?.removeEventListener(
        "change",
        setConnectionSpeed
      )
  }, [])

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollY = containerRef.current.scrollTop
      const direction = currentScrollY > scrollTop ? "down" : "up"
      scrollDirection.current = direction
      setScrollTop(currentScrollY)
    }, 50)

    const currentRef = containerRef.current
    currentRef.addEventListener("scroll", handleScroll)
    return () => currentRef.removeEventListener("scroll", handleScroll)
  }, [scrollTop])

  const getSectionComponent = (type) => {
    const componentMap = {
      "opening": StickyOpening,
      "sticky": highPerformance ? (
        isMobile ? (
          StickyVideoMobile
        ) : (
          StickyVideo
        )
      ) : (
        <div>YO STINKY!!!</div>
      ),
      "sticky-text": StickyImage,
      "sticky-text-opening": StickyImageOpening,
      "image-scroller": ScrollingImages,
      "form": FormComponent,
      "footer": Footer,
    }

    return componentMap[type] || (() => null)
  }
  return (
    <div
      className="scrollable-container"
      // style={{ border: "2px solid red" }}
      ref={containerRef}
    >
      {scrollTop < 100 && <ScrollDownButton containerRef={containerRef} isMobile={isMobile} />}
      {sections.map((section, i) => {
        const show = (isMobile && section.onMobile) || !isMobile
        const Component = getSectionComponent(section.type)
        const commonProps = {
          userHasInteracted: userHasInteracted,
          key: i,
          zIndex: i,
          scrollDirection: scrollDirection.current,
          isMobile: isMobile,
          scrollTop: scrollTop,
          isHighSpeed: isHighSpeed,
          ...(section.videoSrc
            ? { videoSrc: isMobile ? section.videoSrcMobile : section.videoSrc }
            : {}),
          ...(section.imageSrc ? { imageSrc: section.imageSrc } : {}),
          ...(section.loop !== undefined ? { loop: section.loop } : {}),
          ...(section.textContent
            ? {
              textContent: isMobile
                ? section.mobileTextContent
                : section.textContent,
            }
            : {}),
        }
        return show ? (
          <Suspense key={i} fallback={<div></div>}>
            <Component
              {...section}
              {...commonProps}
              containerRef={containerRef}
            />
          </Suspense>
        ) : null
      })}
      <div id="bottom-div" style={{ marginBottom: isMobile ? "85vh" : "60vh" }}></div>
    </div>
  )
}

export default Container
