import { useState, useEffect } from "react"

const useLoading = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Adjust the timeout as needed
    const timer = setTimeout(() => setLoading(false), 1000)
    return () => clearTimeout(timer)
  }, [])

  return loading
}

export default useLoading
