import React from "react"
import TopNavigation from "./extents/TopNavigation"
// import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <TopNavigation />
      <main>{children}</main>

      {/* Footer or other common elements */}
    </>
  )
}

export default Layout
