import React, { useState, useEffect, useRef } from "react"

const ScrollingImages = ({
  totalFrames = 0,
  filenamePrefix,
  foldernamePrefix,
  offset = 0,
  scrollDirection,
}) => {
  const [currentFrame, setCurrentFrame] = useState(0)
  const containerRef = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  // Visibility Observer
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [window.innerWidth])
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { intersectionRatio, boundingClientRect } = entry
          const inTopQuartile = boundingClientRect.top < window.innerHeight / 4
          const inBottomQuartile =
            boundingClientRect.bottom > (window.innerHeight / 4) * 3

          // Adjust opacity based on scroll direction and position
          const opacity = intersectionRatio > 0.2 ? intersectionRatio : 0
          if (scrollDirection === "up" && inTopQuartile) {
            // Specific behavior when scrolling up and in top quartile
          } else if (scrollDirection === "down" && inBottomQuartile) {
            // Specific behavior when scrolling down and in bottom quartile
          }
          entry.target.style.opacity = opacity
        })
      },
      {
        threshold: buildThresholdList(),
      }
    )

    const stickyEl = containerRef.current
    observer.observe(stickyEl)

    return () => {
      observer.unobserve(stickyEl)
    }
  }, [scrollDirection])

  // Image scan observer
  const imageList = Array.from({ length: totalFrames }, (_, i) => {
    const fileNumber = `0000${i + offset}`.slice(-4)
    return `assets/${foldernamePrefix}/${filenamePrefix}-${fileNumber}.jpg`
  })

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { intersectionRatio } = entry
          const scaledRatio = scaleRatio(intersectionRatio, 0.33, 0.67)

          // Calculate the new frame based on the scaled ratio
          const newFrame = Math.floor(scaledRatio * (totalFrames - 1))
          setCurrentFrame(newFrame)
          // console.log("frame", newFrame)
          // Set the container opacity
          containerRef.current.style.opacity = intersectionRatio
        })
      },
      {
        root: null,
        threshold: buildThresholdList(),
      }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [totalFrames])

  return (
    <div ref={containerRef} style={{ opacity: 0 }}>
      <img
        src={imageList[currentFrame]}
        alt={`Frame ${currentFrame}`}
        style={{
          border: "10px solid green",
          width: "100vw",
          height: "100vh",
          position: isMobile ? "static" : "absolute",
          top: 0,
        }}
      />
    </div>
  )
}

export default ScrollingImages

function buildThresholdList() {
  let thresholds = []
  for (let i = 0; i <= 1.0; i += 0.01) {
    thresholds.push(i)
  }
  return thresholds
}
function scaleRatio(ratio, minRatio, maxRatio) {
  if (ratio < minRatio) return 0
  if (ratio > maxRatio) return 1
  return (ratio - minRatio) / (maxRatio - minRatio)
}
