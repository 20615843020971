import React, { useState } from "react"
import Layout from "../components/Layout"
import ScrollingContainer from "../components/ScrollingContainer"
import useLoading from "../hooks/useLoading"

const Home = () => {
  const [highPerformance] = useState(isHighPerformanceDevice)
  const [userHasInteracted, setUserHasInteracted] = useState(false)

  const handleInitialInteraction = () => {
    if (!userHasInteracted) {
      setUserHasInteracted(true)
    }
  }
  const loading = useLoading()
  if (loading) {
    return (
      <div
        className="preloader"
        style={{ opacity: 1, filter: "brightness(200)" }}
      >
        Cloud Apartments...
      </div>
    )
  }
  return (
    <div onPointerDown={handleInitialInteraction}>
      <Layout>
        <ScrollingContainer
          highPerformance={highPerformance}
          userHasInteracted={userHasInteracted}
        />
      </Layout>
    </div>
  )
}

export default Home

function isHighPerformanceDevice() {
  // Check for required features
  const hasWebGL = !!(
    window.WebGLRenderingContext &&
    (document.createElement("canvas").getContext("webgl") ||
      document.createElement("canvas").getContext("experimental-webgl"))
  )

  // Check device memory (if available)
  // @ts-ignore
  // const deviceMemory = navigator.deviceMemory || 0

  // Adjust performance thresholds based on your requirements
  // const minDeviceMemory = 2 // Minimum memory required for high performance (in GB)
  // const minFpsThreshold = 30 // Minimum FPS required for high performance
  // Return true if the device meets both feature and performance criteria
  return hasWebGL // && deviceMemory >= minDeviceMemory
}
