import React, { useEffect, useState } from "react"
import "../../App.css"
// import { useModal } from "../../ModalContext"

const TopNavigation = () => {
  // const { openModal } = useModal()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [window.innerWidth])

  const scrollToForm = () => {
    const signupForm = document.getElementById("bottom-div")
    if (signupForm) {
      signupForm.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  return (
    <nav
      style={{
        position: "absolute",
        top: 0,
        zIndex: 200,
        width: "100%",
      }}
    >
      <div
        style={{
          width: isMobile ? "100%" : "80%",
          display: "flex",
          justifyContent: isMobile ? "space-around" : "space-between",
        }}
      >
        <a href="/" style={{ marginLeft: isMobile ? "6%" : "18.5%" }}>
          <div className="image-container" style={{ opacity: 0 }}>
            <img
              src="icons/logo.svg"
              alt="Cloud Apartments Logo"
              className="fixed-height-image"
            />
          </div>
        </a>
        <button
          className="image-container-2"
          style={{
            fontFamily: "BasisMedium",
            fontSize: isMobile ? "1rem" : "1.25rem",
            color: "white",
            marginRight: isMobile ? "9.5%" : "18.5%",
            opacity: 0,
            border: "none",
            // @ts-ignore
            textAlign: isMobile ? "right" : "",
            width: isMobile ? "300px" : "auto",
          }}
          onClick={scrollToForm}
        >
          Sign up
        </button>
        {/* <a href="/developer">Developer</a>
      <a href="/partner">Partner</a>
      <a href="/about">About</a>
    */}
      </div>
    </nav>
  )
}

export default TopNavigation
