import React, { useRef, useEffect, useState } from "react"

const Text = ({ textContent }) => {
  const textRef = useRef()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [window.innerWidth])

  useEffect(() => {
    const textEl = textRef.current
    if (isMobile && textEl) {
      if (textEl) {
        textEl.style.height = "30vh"
        textEl.style.opacity = 1
      } else {
        textEl.style.height = "100vh"
      }
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMobile) {
          entries.forEach((entry) => {
            if (
              entry.intersectionRatio < 0.7 &&
              entry.intersectionRatio > 0.2
            ) {
              entry.target.style.opacity = 1
            } else {
              entry.target.style.opacity = 0
            }
          })
        }
      },
      { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9] } // Adjust as necessary
    )

    if (!isMobile) {
      observer.observe(textEl)
    } else {
      observer.unobserve(textEl)
    }

    return () => {
      observer.unobserve(textEl)
    }
  }, [window.innerWidth])

  return (
    <div
      ref={textRef}
      style={{
        height: "100vh",
        margin: "auto",
        padding: "2%",
        textAlign: "center",
        fontFamily: "BasisMedium",
        fontSize: isMobile ? "1.5rem" : "3rem",
        opacity: 0,
        textShadow: "15px -5px 40px rgba(255, 255, 255, .5)",
        transition: "opacity .5s ease-in-out",
        lineHeight: isMobile ? "1.5rem" : "3.5rem",
      }}
    >
      {textContent &&
        textContent.map((text, index) => (
          <div
            key={index}
            style={{
              maxWidth: "100vw",
            }}
          >
            {text}
          </div>
        ))}
    </div>
  )
}
export default Text
