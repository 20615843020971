import React, { useEffect, useRef, useState } from "react"
import "../../App.css"
// import { openingScaler } from "../consts"
import ScrollingText from "../bodies/ScrollingText"

const StickyImageOpening = ({
  imageSrc,
  zIndex,
  textContent,
  imageOpacity,
  scrollDirection,
}) => {
  const stickyRef = useRef(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [window.innerWidth])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { intersectionRatio } = entry

          // Adjust opacity based on scroll direction and position
          const opacity = intersectionRatio > 0.3 ? intersectionRatio : 0
          entry.target.style.opacity = opacity
        })
      },
      {
        threshold: buildThresholdList(),
      }
    )

    const stickyEl = stickyRef.current
    observer.observe(stickyEl)

    return () => {
      observer.unobserve(stickyEl)
    }
  }, [scrollDirection])

  return (
    <div
      style={{
        opacity: 1,
        backgroundColor: "black",
      }}
    >
      <div ref={stickyRef} style={{ opacity: 0 }} className="fade-image-2">
        <img
          src={imageSrc}
          alt={textContent}
          style={{
            position: isMobile ? "static" : "absolute",
            top: "-5vh",
            left: 0,
            width: "100vw",
            height: "auto",
            justifyContent: "space-between",
            opacity: imageOpacity || 1,
            // border: "2px solid orange",
          }}
        />
      </div>
      <div
        style={{
          position: isMobile ? "static" : "sticky",
          zIndex: 2000 + zIndex,
          fontSize: "2.5rem",
          color: "white",
          lineHeight: isMobile ? "1.5rem" : "4rem",
          minWidth: "100%",
        }}
      >
        <ScrollingText textContent={textContent} />
      </div>
    </div>
  )
}

export default StickyImageOpening

function buildThresholdList() {
  let thresholds = []
  for (let i = 0; i <= 1.0; i += 0.01) {
    thresholds.push(i)
  }
  return thresholds
}
