import React, { useState } from "react";

const ScrollDownButton = ({ containerRef, isMobile }) => {
  const [display, setDisplay] = useState("block");

  const smoothScrollTo = (target, duration) => {
    const start = containerRef.current.scrollTop;
    const change = target - start;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const animateScroll = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const progress = timeElapsed / duration;
      const easedProgress = easeInOutQuad(progress);
      containerRef.current.scrollTop = start + change * Math.min(easedProgress, 1);
      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const scrollDown = () => {
    setDisplay("none"); // Hide button after scrolling
    // Smooth scroll the container by 1500 pixels over 4000 milliseconds.
    const scrollHeight = isMobile ? window.innerHeight * 1.25 : window.innerHeight * 1.5;

    smoothScrollTo(scrollHeight, 4000);
  };

  return (
    <button
      className="scroll-down-btn"
      style={{ display: display, zIndex: 9999, left: isMobile ? "40vw" : "48vw" }}
      onPointerDown={scrollDown}
    >
      <svg width="30" height="30" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" style={{ backgroundColor: "#000000" }}>
        <path d="M 25,20 L 50,40 L 75,20 L 70,20 L 50,35 L 30,20 Z" />
        <path d="M 25,40 L 50,60 L 75,40 L 70,40 L 50,55 L 30,40 Z" />
        <path d="M 25,60 L 50,80 L 75,60 L 70,60 L 50,75 L 30,60 Z" />
      </svg>
    </button>
  );
};

export default ScrollDownButton;
