import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { ModalProvider } from "./ModalContext"

const customFonts = {
  fonts: {
    heading: "TWK300, sans-serif", // Replace with your font
    body: "TWK300, sans-serif", // Replace with your font
    // You can also add other font styles if needed
  },
}

// Extend the theme
const customTheme = extendTheme(customFonts)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
  <ChakraProvider theme={customTheme}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </ChakraProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
